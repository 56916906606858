<template>
	<div class="full-width">
		<div id="frame"></div>
	</div>
	
</template>

<script type="text/javascript">
	export default{
		name: "sub-view"
	};
</script>